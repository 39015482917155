import React, { Suspense, lazy } from "react";

import {
  Navigate,
  useRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";
// components
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const localUser = localStorage.getItem("userData");
  const userData = localUser ? JSON.parse(localUser) : "";
  React.useEffect(() => {
    console.log("PATH::::", pathname);
    if (isAuthenticated) {
      if (pathname === "/") {
        return navigate("/dashboard");
      }
      if (userData.role === "CLIENT_ADMIN") {
        if (
          pathname.includes("/list-admins") ||
          pathname === "/create-web-user"
        ) {
          return navigate("/404");
        }
      }
    } else if (
      !pathname.includes("/verify") &&
      !pathname.includes("download") &&
      !pathname.includes("auth") &&
      !pathname.includes(".well-known")
    ) {
      return navigate("/auth/login");
    } // eslint-disable-next-line
  }, [navigate, isAuthenticated, pathname]);
  const AppleAppAssociation = () => {
    const appleAppAssociationData = {
      applinks: {
        apps: [],
        details: [
          {
            appID: "APHA856LEU.com.capacity.cultureradar",
            paths: ["*"],
          },
        ],
      },
    };

    return <pre>{JSON.stringify(appleAppAssociationData)}</pre>;
  };
  const settingsChildren = [
    { path: "settings", element: <Navigate to="/" replace /> },
    //  client Admin
    { path: "client-admin", element: <ClientAdmin /> },
    { path: "add-user", element: <AddUser /> },
    { path: "create-company", element: <CreateCompany /> },
    { path: "edit-company/:id", element: <CreateCompany /> },
    { path: "create-groups", element: <CreateGroupUser /> },
    { path: "edit-groups/:id", element: <CreateGroupUser /> },

    // Notifications
    { path: "notifications", element: <Notifications /> },
    { path: "notifications-create", element: <CreateNotification /> },
    { path: "notifications-edit/:id", element: <CreateNotification /> },

    //Module Video
    { path: "module-videos", element: <ModuleVideoList /> },
  ];

  if (userData?.role === "SUPER_ADMIN") {
    settingsChildren.push({ path: "audit-logs", element: <AuditLogsList /> });
  }

  return useRoutes([
    // unautuneticated routes
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    // verification
    {
      path: "verify",
      children: [
        { path: ":token", element: <VerifyCode /> },
        { path: "otp", element: <VerifyCodeForm /> },
      ],
    },
    {
      path: "cms",
      element: <DashBoardLayout />,
      children: [
        { path: "cms", element: <Navigate to="/" replace /> },
        { path: "", element: <Home /> },
        {
          path: "new-ringo",
          element: <NewRingo />,
        },
        { path: "create-ringo", element: <CreateRingo /> },
        { path: "ringos", element: <RingoList /> },
        { path: "edit-ringo/:id", element: <CreateRingo /> },
        {
          path: "survey",
          element: <SurveyList />,
        },
        { path: "survey-create", element: <CreateSurvey /> },
        { path: "survey-edit/:id", element: <EditSurvey /> },

        // tags
        { path: "tags-create", element: <CreateTag /> },
        { path: "tags-edit/:id", element: <CreateTag /> },
        { path: "tags", element: <TagList /> },

        // statement
        { path: "statements", element: <StatementList /> },
        { path: "statements-create", element: <CreateStatement /> },
        { path: "statements-edit/:id", element: <CreateStatement /> },

        // values
        { path: "values", element: <ValuesList /> },
        { path: "values-create", element: <CreateValue /> },
        { path: "values-edit/:id", element: <CreateValue /> },

        // modules
        { path: "module", element: <ModuleList /> },
        { path: "module-create", element: <CreateModule /> },
        { path: "module-edit/:id", element: <CreateModule isEdit /> },

        // priorities module
        { path: "priorities", element: <PriModuleList /> },
        { path: "priorities-create", element: <PriCreateModule /> },
        { path: "priorities-edit/:id", element: <PriCreateModule /> },

        // RewardList
        { path: "rewards", element: <RewardList /> },
        { path: "rewards-create", element: <CreateReward /> },
        { path: "rewards-edit/:id", element: <CreateReward /> },

        { path: "interest", element: <Interest /> },
        // Program
        { path: "programs", element: <ProgramList /> },
        { path: "programs-create", element: <CreateProgram /> },
        { path: "programs-edit/:id", element: <CreateProgram /> },
      ],
    },
    {
      path: "library",
      element: <DashBoardLayout />,
      children: [
        { path: "programs", element: <Library tab="programs" /> },
        { path: "modules", element: <Library tab="modules" /> },
        { path: "surveys", element: <Library tab="surveys" /> },
        { path: "preview/:id", element: <PreviewLibrary /> },
        { path: "create-survey", element: <CreateSurveyTemplate /> },
        { path: "create-module", element: <CreateModuleTemplate /> },
        { path: "create-program", element: <CreateProgramTemplate /> },
        { path: "edit-template/:id", element: <EditTemplate /> },
      ],
    },
    {
      path: "analysis",
      element: <DashBoardLayout />,
      children: [
        // indexes
        { path: "index", element: <IndexList /> },
        { path: "index-create", element: <CreateIndex /> },
        { path: "index-edit/:id", element: <CreateIndex /> },
        { path: "reports", element: <Reports />}
      ],
    },
    {
      path: "workflow-builder",
      element: <DashBoardLayout />,
      children: [
        {
          path: "logic-builder",
          element: <LogicBuilderList />,
        },
        {
          path: "logic-builder-create",
          element: <CreateLogicBuilder />,
        },
        {
          path: "logic-builder-edit/:id",
          element: <CreateLogicBuilder />,
        },
      ],
    },
    {
      path: "notifications",
      element: <DashBoardLayout />,
      children: [
        // Notifications
        { path: "notifications", element: <Notifications /> },
        { path: "notifications-create", element: <CreateNotification /> },
        { path: "notifications-edit/:id", element: <CreateNotification /> },
      ],
    },
    {
      path: "location",
      element: <DashBoardLayout />,
      children: [
        {
          path: "gps-ring-fencing-tool",
          element: <GpsRingFencingTool />,
        },
        {
          path: "gps-ring-fencing-tool-create",
          element: <CreateLocationGps />,
        },
        {
          path: "gps-ring-fencing-tool-edit/:id",
          element: <EditLocationGps />,
        },
      ],
    },
    {
      path: "settings",
      element: <DashBoardLayout />,
      children: settingsChildren,
    },
    {
      path: "chat",
      element: <DashBoardLayout />,
      children: [{ path: "", element: <Chat /> }],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: <DashBoardLayout />,
      children: [
        { path: "dashboard", element: <Navigate to="/" replace /> },
        { path: "", element: <Home /> },
      ],
    },
    {
      path: "profile",
      element: <DashBoardLayout />,
      children: [{ path: "", element: <Profile /> }],
    },
    {
      path: "create-web-user",
      element: <DashBoardLayout />,
      children: [{ path: "", element: <CreateWebUser /> }],
    },
    {
      path: "list-admins",
      element: <DashBoardLayout />,
      children: [
        // { path: "dashboard", element: <Navigate to="/" replace /> },
        { path: "", element: <ListClientAdmin /> },
        { path: "list-admins-edit/:id", element: <CreateWebUser /> },
      ],
    },
    { path: "download", element: <Download /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
    // {
    //   path: ".well-known",
    //   children: [
    //     {
    //       path: "apple-app-site-association",
    //       element: {
    //         applinks: {
    //           apps: [],
    //           details: [
    //             {
    //               appID: "APHA856LEU.com.capacity.cultureradar",
    //               paths: ["*"],
    //             },
    //           ],
    //         },
    //       },
    //     },
    //   ],
    // },
    { path: "download-mobile", element: <DownloadMobile /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);
const ChangePassword = Loadable(
  lazy(() => import("../pages/authentication/ChangePassword"))
);
const Download = Loadable(lazy(() => import("../pages/Download")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
// dashboard routes
const NewRingo = Loadable(lazy(() => import("../pages/dashboard/newRingo")));

const SurveyList = Loadable(lazy(() => import("../pages/dashboard/survey")));
const CreateSurvey = Loadable(
  lazy(() => import("../pages/dashboard/survey/createSurveyNew"))
);
const EditSurvey = Loadable(
  lazy(() => import("../pages/dashboard/survey/editSurveyNew"))
);
const Home = Loadable(lazy(() => import("../pages/home")));
const ModuleList = Loadable(lazy(() => import("../pages/dashboard/module")));
const PriModuleList = Loadable(
  lazy(() => import("../pages/dashboard/Prioritoiesmodule"))
);
const PriCreateModule = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/Prioritoiesmodule/priModule/createPriModule")
  )
);
const CreateModule = Loadable(
  lazy(() => import("../pages/dashboard/module/createModeule/createModuleNew"))
);
const CreateIndex = Loadable(
  lazy(() => import("../pages/dashboard/indexes/createIndex/createIndex"))
);
const IndexList = Loadable(lazy(() => import("../pages/dashboard/indexes")));
const Reports = Loadable(lazy(() => import("../pages/dashboard/reports")));

const CreateValue = Loadable(
  lazy(() => import("../pages/dashboard/values/createValue/createValue"))
);

const DashBoardLayout = Loadable(lazy(() => import("../layouts/dashboard")));
const ValuesList = Loadable(lazy(() => import("../pages/dashboard/values")));

const StatementList = Loadable(
  lazy(() => import("../pages/dashboard/statements"))
);
const CreateStatement = Loadable(
  lazy(() => import("../pages/dashboard/statements/createStatement/index"))
);

const RewardList = Loadable(lazy(() => import("../pages/dashboard/rewards")));
const TagList = Loadable(lazy(() => import("../pages/dashboard/tag")));
const CreateTag = Loadable(
  lazy(() => import("../pages/dashboard/tag/createTag"))
);
const CreateReward = Loadable(
  lazy(() => import("../pages/dashboard/rewards/createreward/createReward"))
);

const ClientAdmin = Loadable(
  lazy(() => import("../pages/dashboard/clientManagement/clientAdmin"))
);

const AddUser = Loadable(
  lazy(() => import("../pages/dashboard/clientManagement/createNewClient"))
);
const Notifications = Loadable(
  lazy(() => import("../pages/dashboard/notifications"))
);

const ModuleVideoList = Loadable(
  lazy(() => import("../pages/dashboard/moduleVideo"))
);

const CreateNotification = Loadable(
  lazy(
    () =>
      import(
        "../pages/dashboard/notifications/createNotification/createNotification"
      )
  )
);

const CreateCompany = Loadable(
  lazy(() => import("../pages/dashboard/clientManagement/createNewCompany"))
);

const LogicBuilderList = Loadable(
  lazy(() => import("../pages/dashboard/logicBuilder"))
);
const CreateLogicBuilder = Loadable(
  lazy(() => import("../pages/dashboard/logicBuilder/createLogicBuilder"))
);
const GpsRingFencingTool = Loadable(
  lazy(() => import("../pages/dashboard/gpsRingFencingTool"))
);
const CreateLocationGps = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/gpsRingFencingTool/createGpsRingFencingTool")
  )
);
const EditLocationGps = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/gpsRingFencingTool/createGpsRingFencingTool")
  )
);
const Profile = Loadable(lazy(() => import("../pages/profile")));
const Interest = Loadable(lazy(() => import("../pages/dashboard/interest")));
const CreateWebUser = Loadable(
  lazy(() => import("../pages/profile/createWebUser"))
);
const CreateProgram = Loadable(
  lazy(() => import("../pages/dashboard/programs/createProgram/createProgramNew"))
);
const ProgramList = Loadable(lazy(() => import("../pages/dashboard/programs")));
const RingoList = Loadable(lazy(() => import("../pages/dashboard/newRingo")));

const CreateRingo = Loadable(
  lazy(() => import("../pages/dashboard/newRingo/createRingoNew"))

);

// const CreateRingo = Loadable(
//   lazy(() => import("../pages/dashboard/newRingo/createRingoNew"))
//   // lazy(() => import("../pages/dashboard/newRingo/createRingo/createRingo"))

// );
const CreateGroupUser = Loadable(
  lazy(() => import("../pages/dashboard/clientManagement/createGroupUser"))
);
const ListClientAdmin = Loadable(
  lazy(() => import("../pages/dashboard/clientAdmin"))
);

// const VerifyOtp = Loadable(
//   lazy(() => import("../pages/authentication/VerifyOtp"))
// );

const VerifyCodeForm = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);

const Chat = Loadable(lazy(() => import("../pages/dashboard/chat")));

const Library = Loadable(lazy(() => import("../pages/dashboard/library")));

const PreviewLibrary = Loadable(
  lazy(() => import("../pages/dashboard/library/previewTemplate"))
);

const CreateSurveyTemplate = Loadable(
  lazy(() => import("../pages/dashboard/library/createTemplate/createSurvey"))
);

const CreateModuleTemplate = Loadable(
  lazy(
    () => import("../pages/dashboard/library/editTemplate/editAndCreateModule")
  )
);

const CreateProgramTemplate = Loadable(
  lazy(
    () => import("../pages/dashboard/library/editTemplate/editAndCreateProgram")
  )
);

const EditTemplate = Loadable(
  lazy(() => import("../pages/dashboard/library/editTemplate/index"))
);

const DownloadMobile = Loadable(lazy(() => import("../pages/DownloadMobile")));

const AuditLogsList = Loadable(lazy(() => import("../pages/dashboard/auditLogs")));
